import React, { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import Content from "../../../containers/Content";
import TopBar from "../../../containers/TopBar";
import { DashboardLayout } from "../../../libs/Layout";
import { LoadingIndicator } from "../../../elements/frontend/src/components";
import { navigator } from "nested-navigator";

interface Client {
  client_id: string;
  metadata: { scope: string; data: any }[];
  name: string;
  contact: {
    first_name: string;
    last_name: string;
    telephone: string;
  };
  address: {
    line_1: string;
    postal: string;
    city: string;
    country: string;
  };
}
interface RootState {
  currentUser: {
    authenticated: boolean;
    forceChange?: boolean;
    errors: string[];
    loading?: boolean;
    selectedClient?: Client;
    clients?: Client[];
    transactions?: { client_id: string; [key: string]: any }[];
    datastore?: {
      phases: { value: string }[];
      stake: { value: string }[];
      types: { value: string }[];
      period: { value: string }[];
    };
  };
}


// Dashboard Context
const initialDashboardData = {
  company_name: "",
  address: {
    street: "",
    postal: "",
    city: "",
    country: "", // DEU
  },
  contact: {
    first_name: "",
    last_name: "",
    telephone: "",
  },
  investment: {
    categories: [],
    sub_categories: [],
    phases: [],
    region: [],
    invest: "",
    revenue: "",
    ebitda: "",
    stake: [],
    types: [],
    period: [],
    contribution: {
      strategic_investor: false,
      financial_investor: false,
      wachstums_investor: false,
    },
    horizon: {
      less_five: false,
      more_five: false,
    },
  },
  transactions: [],
  form_state: {
    initial: true,
    valid: false,
  },
  investor_content: {
    steps: [
      { label: "Überblick", stepId: "OVERVIEW" },
      { label: "Status", stepId: "STATUS" },
      { label: "Details", stepId: "DETAILS" },
    ],
    currentStep: "OVERVIEW",
    showStatus: false,
    showDetails: false,
  },
  scrollTo: "",
  detailsContentLoading: true,
};

export const DashbordContext = createContext(initialDashboardData);
export const DashboardDispatch = createContext((state: any) => state);

const reducerDashboard = (state: any, action: any) => {
  return { ...state, ...action.payload };
};

const Home = () => {
  const [dashboardContext, dashboardDispatch] = useReducer(reducerDashboard, initialDashboardData);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [selectedClientId /* setSelectedClientId */] = useState(
    currentUser.selectedClient?.client_id || ""
  );

  const selectedClient = useMemo(() => {
    if (!currentUser.clients) return {};
    return currentUser.clients.find((client) => client.client_id === selectedClientId);
  }, [currentUser.clients, selectedClientId]);

  const isClient = (client: any): client is Client => {
    return client && client.metadata !== undefined;
  };
  

  // for the data saved in scope "investment"
  const selectedClientInvestmentData = useMemo(() => {
    if (!isClient(selectedClient)) return {};
    const scopeInvestment = selectedClient.metadata.find(
      (item: { scope: string }) => item.scope === "investment"
    );
  
    if (scopeInvestment) {
      return scopeInvestment.data;
    }
    return {};
  }, [selectedClient]);
  
  
  

  // for the data saved in transactions
  const selectedClientTransactionsData = useMemo(() => {
    if (!currentUser.transactions) return [];
    const transactions = currentUser.transactions.filter(
      (item) => item.client_id === selectedClientId
    );
    if (transactions) {
      return transactions;
    }
    return [];
  }, [currentUser.transactions, selectedClientId]);

  useEffect(() => {
    if (selectedClient === undefined) return;

    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        company_name: navigator(selectedClient as Client).navigateTo("name").value() ?? "",
        contact: {
          first_name: navigator(selectedClient as Client).navigateTo("contact.first_name").value() ?? "",
          last_name: navigator(selectedClient as Client).navigateTo("contact.last_name").value() ?? "",
          telephone: navigator(selectedClient as Client).navigateTo("contact.telephone").value() ?? "",
        },
        address: {
          street: navigator(selectedClient as Client).navigateTo("address.line_1").value()?.toString() || "",
          postal: navigator(selectedClient as Client).navigateTo("address.postal").value()?.toString() || "",
          city: navigator(selectedClient as Client).navigateTo("address.city").value()?.toString() || "",
          country: navigator(selectedClient as Client).navigateTo("address.country").value()?.toString() || "",
        },
        investment: selectedClientInvestmentData
          ? {
              categories: selectedClientInvestmentData.categories || [],
              sub_categories: selectedClientInvestmentData.sub_categories || [],
              phases: selectedClientInvestmentData.phases || (currentUser.datastore?.phases.map((item) => item.value) || []),
              region: selectedClientInvestmentData.region || ["DEU"],
              invest: selectedClientInvestmentData.invest || "",
              revenue: selectedClientInvestmentData.revenue || "",
              ebitda: selectedClientInvestmentData.ebitda || "",
              stake: selectedClientInvestmentData.stake || (currentUser.datastore?.stake.map((item) => item.value) || []),
              types: selectedClientInvestmentData.types || (currentUser.datastore?.types.map((item) => item.value) || []),
              period: selectedClientInvestmentData.period || (currentUser.datastore?.period.map((item) => item.value) || []),
              contribution: {
                strategic_investor:
                  selectedClientInvestmentData.contribution?.strategic_investor || false,
                financial_investor:
                  selectedClientInvestmentData.contribution?.financial_investor || false,
                wachstums_investor:
                  selectedClientInvestmentData.contribution?.wachstums_investor || false,
              },
              horizon: {
                less_five: selectedClientInvestmentData.horizon?.less_five || false,
                more_five: selectedClientInvestmentData.horizon?.more_five || false,
              },
            }
          : {},
        transactions: selectedClientTransactionsData,
      },
    });
  }, [currentUser.datastore?.period, currentUser.datastore?.phases, currentUser.datastore?.stake, currentUser.datastore?.types, selectedClient, selectedClientInvestmentData, selectedClientTransactionsData]);

  return (
    <>
      <DashboardLayout>
        <TopBar />
        <DashboardDispatch.Provider value={dashboardDispatch}>
          <DashbordContext.Provider value={dashboardContext}>
            <Content />
          </DashbordContext.Provider>
        </DashboardDispatch.Provider>
      </DashboardLayout>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
    </>
  );
};

export default Home;
