/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,

    APP_API_ID: "app-be",
    NOTES_API_ENDPOINT: "/notes",
    ACCOUNT_API_ENDPOINT: "/account",
    CLIENT_API_ENDPOINT: "/clients",
    DOCUMENTS_API_ENDPOINT: "/documents",
    USER_API_ENDPOINT: "/users",
    EVENT_API_ENDPOINT: "/events",
    TRANSACTION_API_ENDPOINT: "/transactions",
    METADATA_API_ENDPOINT: "/metadata",
    SIGNUP_API_ID: "registration",
    FORMS_API_ENDPOINT: "/forms",
    EMAILS_API_ENDPOINT: "/emails",
    COUPON_API_ENDPOINT: "/coupons",
    FILE_API_ENDPOINT: "/files",
    CUSTOMER_API_ENDPOINT: "/customers",
    ADVISOR_API_ENDPOINT: "/advisors",
    LOGINS_API_ENDPOINT: "/logins",
    WARMUP_API_ENDPOINT: "/warmup",
  },

  /**
   * @memberof configuration
   * @property {object}app_event - Urn items
   */
  app_event: {
    TYPES: {
      LOGIN: "login",
    },
    URN: {
      WEBAPP: "webapp",
      SUSA: "susa",
      PROFILE: "profile",
      LOGO: "logo",
      IMAGE: "image",
    },
  },

  /**
   * @memberof configuration
   * @property {object}shared_links - Shared links.
   */
  shared_links: {
    IMPRINT: "https://www.beteiligungsboerse.eu/impressum/",
    TERMS_URL: "https://www.beteiligungsboerse.eu/agbs/",
    PRIVACY_URL: "https://www.beteiligungsboerse.eu/datenschutzerklaerung/",
  },

  /**
   * @memberof configuration
   * @property {object}product - product settings.
   */
  defaults: {
    PRODUCT_ID: "investor",
    PRICING_ID: "investor_01",
  },
};

export default config;
